<template>
<div class="flex flex-col subpixel-antialiased relative h-screen ">
  <Header />
  <nuxt class="flex flex-col p-1 sm:px-2 sm:pt-2 lg:px-4 lg:pt-4 relative overflow-hidden"
        :style="compactHeight?'min-width:320px;max-height: calc(100vh - 48px);':'min-width:320px;max-height: calc(100vh - 68px);'" />
  <Footer v-if="!compactHeight" />
</div>
</template>

<script>
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import usertools from '~/mixins/usertools.js'

export default {
  components: { Header, Footer },
  mixins: [ usertools ],
  data () {
    return {
      clientHeight: document.documentElement.clientHeight,
    }
  },
  computed: {
    compactHeight() { return this.clientHeight < 500 }
  },
  mounted() { // affiliate check
    this.checkUserVerified()
    if (!this.isLoggedIn()) this.$router.push('/account/login')
      else if (!this.isAdministrator() && !this.isEditor()) this.$router.push('/')

    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function() { window.removeEventListener('resize', this.handleResize) },
}
</script>

<style>
 html { box-sizing: border-box !important; }
</style>



