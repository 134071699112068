// import LoadScript from 'vue-plugin-load-script'
// Vue.use(LoadScript) // allow us to lazy load heavy code bits in particular admin pages

export default function ({ app, store, redirect }) {
  // set up the store to react on firebase auth changes
  app.$fireAuthObj().onAuthStateChanged((user) => {
    console.log('Auth state change', !!user)
    if (user) { // User is signed in.
      // console.log('plugin auth()', 'user authenticated')
      store.dispatch('setUser', {user} )
      store.dispatch('progress/bindStudentRef', {user} )
      store.dispatch("courses/bindCoursesRef")
    } else {  // User not signed in.
      // console.log('plugin auth()', 'user de-authenticated')
      store.dispatch('resetUser')
      store.dispatch('progress/unBindStudentRef')
    }
  })
}
