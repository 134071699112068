<template>
<div class="flex flex-col subpixel-antialiased relative h-screen w-screen overflow-x-hidden">
  <Header />
  <!-- The default template has a scrollable main body area  -->
  <div class="fixed overflow-y-auto p-0  "
    :style="true || compactHeight?
    'top:40px; left:0px; right:0px; bottom:0;':
    'top:40px; left:0px; right:0px; bottom:40px;'"
  >
    <nuxt class="flex flex-col h-full w-full relative" />
  </div>
  <Footer v-if="!compactHeight" />
</div>
</template>

<script>
  import Header from '~/components/Header'
  import Footer from '~/components/Footer'
  import usertools from '~/mixins/usertools.js'

  export default {
    components: { Header, Footer },
    data () {
      return {
        // clientHeight: document.documentElement.clientHeight,
      }
    },
    computed: {
      compactHeight() { return this.clientHeight < 500 }
    },
    mixins: [ usertools ],
    methods: {
      handleResize() { this.clientHeight = document.documentElement.clientHeight },
      checkForUpdate() {
        // this does not work.
        // if ('serviceWorker' in navigator) {
        //   navigator.serviceWorker.getRegistrations().then((registrations) => {
        //     for (let registration of registrations) registration.update()
        //   })
        // }
      },
    },
    watch: {
      '$route'() { this.checkUserVerified() },
      user() { this.checkUserVerified() },
    },

    mounted: function() {
      window.addEventListener('resize', this.handleResize)
      // this.checkForUpdate()
    },
    beforeDestroy: function() {
      window.removeEventListener('resize', this.handleResize)
    },
  }
</script>

