<template>
<header class="top-0 py-2 px-5 shadow flex flex-col items-left text-blue-800 bg-white-100 relative z-50 w-screen"
  :class="[dropDownMenu ? 'h-10' : 'h-auto max-h-screen overflow-y-scroll']" >

  <div class="flex-row inline-flex items-center justify-between text-blue-800 w-full self-start" style="min-width:300px">
    <!-- logo link  -->
    <nuxt-link to="/" class="flex items-center -mt-2 hover:text-blue-600 cursor-pointer sticky">
      <img class="w-16 -ml-6 -mb-5 -mt-2 sm:-mt-4 pr-2" src="/icon.png" />
      <div class="m-0 p-0 pt-2 text-lg font-cabin ml-1 sm:ml-2">
        <span class="xs:hidden">BedROCK!</span>
        <span class="hidden xs:inline sm:hidden">BedROCK! Mastery </span>
        <span class="hidden sm:inline"> BedROCK! Reflexive Mastery </span>
      </div>
    </nuxt-link>

    <!-- Hamburger menu -->
    <div class="justify-end sm:hidden fill-current items-end -mt-1 mr-4 cursor-pointer " @click.prevent="toggleMenu"
       :class="[user && account?'mr-3':'-mr-1']">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/></svg>
      <template v-if="user && account">
        <img :src="user.photoURL || '/profile.svg'" src-placeholder="/profile.svg"
          class="w-10 h-10 absolute top-0 right-0 fill-current p-0 -mr-2 -mt-0 rounded-full shadow border-gray-500"/>
      </template>
    </div>

    <!-- Dropdown menus -->
    <div v-if="dropDownMenu" class="hidden sm:flex justify-end fill-current items-end -mt-2">
      <!-- <dropdown v-if="isAdministrator()" :menuitems="resourcesMenu" title="Resources" wid="w-40" pos="-ml-10" /> -->
      <dropdown v-if="isEditor()" :menuitems="adminMenu" title="Admin" wid="w-48" pos="-ml-10" />
      <dropdown :menuitems="modulesMenu" title="Courses" wid="w-64" pos="-ml-16" />
      <dropdown :menuitems="accountMenu" title="" mode="account" wid="w-56" pos="-ml-32" />
    </div>
  </div>

  <!-- Inline menus -->
  <div v-if="!dropDownMenu" class="block sm:hidden text-left cursor-pointer relative p-0 m-0 sm:mx-3 mt-5 w-full mb-2">
    <inmenu v-if="isEditor()" :menuitems="adminMenu" title="Admin" mode="dropdown" />
    <inmenu :menuitems="modulesMenu" title="Courses" mode="dropdown" />
    <inmenu :menuitems="accountMenu" mode="account" />
  </div>
</header>
</template>


<script>
import usertools from '~/mixins/usertools'
import booktools from '~/mixins/booktools'
import progresstools from '~/mixins/progresstools'
import dropdown from '~/components/dropdownmenu'
import inmenu from '~/components/inlinemenu'
import pkg from '~/./package.json'
// import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [usertools, booktools, progresstools],
  components: { dropdown, inmenu },
  data() {
    return {
      windowWidth: 0,
      dropDownMenu: true,
      menuItem: [false, false, false, false],
    }
  },

  methods: {
    // drop-down menu state
    toggleMenu() { this.dropDownMenu = !this.dropDownMenu; },
    showMenu(item) {
      if (!item || !item.hasOwnProperty('type') ||
        item.type==='public' || item.type==='' || this.isAdministrator()) return true
      else if (item.type==='editor') return this.isEditor()
    },
    handleResize() { this.windowWidth = window.innerWidth; },
    setPublicmodulesMenuItems() {
      this.menuContent.courses.items = this.publicmodulesMenuItems
    },
    userCourseLinks(account) {
      // let courses = this.account.courses | []
      // return courses.map(cid => {
      //   let course = this.allCourses[cid]
      //   return {a: course.title, href:`/user/course/${cid}`, class: 'ml-2 text-sm'}
      // })
      return [
        {a: '"course title"', href:`/user/course/${1}`, class: 'ml-2 text-sm'},
        {a: '"course title"', href:`/user/course/${1}`, class: 'ml-2 text-sm'},
      ]
    },


    initializeCourses() {
      // if (!this.allCourses.length) this.$store.dispatch("courses/bindCoursesRef")
      // setTimeout(()=>{ if (!this.allCourses.length) this.initializeBooks() }, 2000)
    },
    initializeBooks() {
      // if (!this.books.length) this.$store.dispatch("books/bindBooksRef")
      // setTimeout(()=>{ if (!this.books.length) this.initializeBooks() }, 2000)
    },
  },





  computed: {
    // ...mapState([ 'appVersion' ]),

    courseLookup() {
      let result = {}
      if (this.allCourses) for (let course of this.allCourses) { result[course.id] = course }
      return result
    },
    widerThan640() { // event that fires when screen goes over 640 to show inline menu
      return this.windowWidth > 640
    },
    // resourcesMenu() { // return this.menuContent.resources
    //   let items = [
    //       // {a:'Tailwind Docs', href:'https://tailwindcss.com/docs/container'},
    //       // {a:'Free Illustrations', href:'https://undraw.co/'},
    //       // {a:'Hero SVG Icons', href:'https://github.com/sschoger/heroicons-ui'},
    //       // {a:'Zond SVG Icons', href:'http://www.zondicons.com/'},
    //       // {a: "Adam's SVG Icons", href:'https://github.com/adamwathan/entypo-optimized'},
    //       // {a:'Hero SVG Patterns', href:'http://www.heropatterns.com/'},
    //     ]
    //   return items
    // },
    adminMenu() {
      //return this.menuContent.admin
      let items = [
          {a:'Users', href:'/admin/user-access', type: 'admin', img: '/profile.svg'},
          {a:'Modules', href:'/admin/books', type: 'editor', img: '/audiobook.svg'},
          {a:'Flashcards', href:'/admin/flashcards', type: 'editor', img: '/flashcards.svg'},
          {a:'', href:'/'},
          // {a:'Customers', href:'/admin/payouts'},
          // {a:'Affiliates', href:'/admin/payouts'},
          {a:'Stripe Revenue', href:'https://dashboard.stripe.com/dashboard', type: 'admin', img: '/payment.svg'},
          {a:'Google Analytics', href:'https://analytics.google.com/analytics/web/#/report-home/a78044600w180162578p178338161', type: 'admin', img: '/analytics.svg'},
          {a:'Firebase', href:'https://console.firebase.google.com/u/0/project/bedrock-math/overview', type: 'admin', img: '/firebase.svg'},
          {a:'Planning Document', href:'https://docs.google.com/document/d/1QibvKrEi_XShp7ck1rfuGsQx8RkjQc7cVIAKew5yxyM/edit#', type: 'admin', img: '/document.svg'},
      ]
      return items
    },
    modulesMenu() {
      if (!this.allCourses) return
      let items = this.allCourses.filter(c => (!c.private)).map(c => {
        return {a: c.title, href: `/courses/${c.id}`, img: c.art.cover}
      })
      items.unshift( {a:'', href:'/'} )
      items.unshift({ a:'Bedrock Modules', href:`/courses/`, img: '/course.svg' })

      if (this.isAdministrator() || this.isEditor()) {
        items.push( {a:'', href:'/'} )
        this.allCourses.filter(c => (c.private)).map(c => {
          items.push({a: c.title, href: `/courses/${c.id}`, img: c.art.cover, style: 'additional'})
        })
      }
      return items
    },
    accountMenu() {
      let account = this.account
      if (!account || !this.isLoggedIn(account)) return []
      let lang='ar'
      var items =  []
      if (this.isStudent(this.account)) {
        // continue recent activity
        if (this.mostRecentCourse) {
          let {courseid, bookid} = this.mostRecentCourse
          let title = (this.booksLookup[bookid].title || '').substr(0,10)
          items.push( {a: `Resume Course (${title})`, href:`/user/library/${bookid}`} )
        }
        // flashcards, if any
        if (this.flashcardsDueList && this.flashcardsDueList.length>0) {
          try { let lang = this.flashcardsDueList[0].split('_')[1].split('-')[1] } catch {}
          if (lang) items.push( {a: `Flashcards Due (${this.flashcardsDueList.isEditorlength})`, href:`/user/flashcards/${lang}`} )
        }
        // divider, if needed
        if (items.length) items.push( {a:'', href:'/'} )
        // part two of student section
        items.push( {a: 'Dashboard', href:`/user/course`, img:'/dashboard.svg'} )
        // user courses
        if (account.courses && this.allCourses) for (var cid of account.courses) {
          let course = this.courseLookup[cid]
          if (course) items.push( {a: course.title, href:`/user/course/${cid}`, class: 'ml-2 text-sm', img: course.art.cover || '/course_sm.svg'} )
        }
        items.push( {a: 'Flashcards', href:`/user/flashcards`, img:'/flashcards.svg'} )
        items.push( {a: 'Library', href:`/user/library`, img:'/library.svg'} )
        items.push( {a:'', href:'/'} )
      }
      // logout, account etc
      items.push( {a: 'Profile', href:'/user/account/profile', img: '/profile.svg'} )
      items.push( {a: 'Account', href:'/user/account', img: '/account.svg'} )
      items.push( {a: 'Logout', href:'/account/logout', img: '/logout.svg'})
      return items
    }
  },





  watch: {
    widerThan640() { this.dropDownMenu = true },
    $route (to, from){ this.dropDownMenu = true },
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  mounted() {
    // this.initializeBooks()
    // this.initializeCourses()
  },
}
</script>


