import firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyA91X_yuc9Jlz46If0p-LIH1pbYplcPHlo","authDomain":"bedrock-math.firebaseapp.com","databaseURL":"https:\u002F\u002Fbedrock-math.firebaseio.com","projectId":"bedrock-math","storageBucket":"bedrock-math.appspot.com","messagingSenderId":"333057001573","appId":"1:333057001573:web:2e535616436fdadb819e8c","measurementId":"G-L93ZP4502M"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** -------------------------------------- FIREBASE AUTH ---------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/auth')

  const fireAuth = session.auth()
  const fireAuthObj = firebase.auth
  inject('fireAuth', fireAuth)
  inject('fireAuthObj', fireAuthObj)

  // persistence should only be enabled client side
  if (process.client) {
    const persistence = firebase.auth.Auth.Persistence.LOCAL

    try {
      await fireAuth.setPersistence(persistence)
    } catch (err) {
      if (err.code === 'auth/invalid-persistence-type') {
        console.warn(`[@nuxtjs/firebase]: Invalid persistence type '${persistence}' provided`)
      } else if (err.code === 'auth/unsupported-persistence-type') {
        console.warn(`[@nuxtjs/firebase]: Persistence type '${persistence}' is not supported in this environment.`)
      }
    }
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE REALTIME DB ----------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/database')

  const fireDb = session.database()
  const fireDbObj = firebase.database
  inject('fireDb', fireDb)
  inject('fireDbObj', fireDbObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** -------------------------------------- FIREBASE STORAGE ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/storage')

  const fireStorage = session.storage()
  const fireStorageObj = firebase.storage
  inject('fireStorage', fireStorage)
  inject('fireStorageObj', fireStorageObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE FUNCTIONS ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/functions')

  const fireFunc = session.functions()
  const fireFuncObj = firebase.functions

  inject('fireFunc', fireFunc)
  inject('fireFuncObj', fireFuncObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE MESSAGING ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Messaging can only be initiated on client side
  if (process.client) {
    await import('firebase/messaging')

    if (firebase.messaging.isSupported()) {
      const fireMess = session.messaging()
      const fireMessObj = firebase.messaging

      if (config.fcmPublicVapidKey) {
        fireMess.usePublicVapidKey(config.fcmPublicVapidKey)
      }

      inject('fireMess', fireMess)
      inject('fireMessObj', fireMessObj)
    }
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE PERFORMANCE ------------------------------------ **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Performance can only be initiated on client side
  if(process.client) {
    await import('firebase/performance')

    const firePerf = session.performance()
    const firePerfObj = firebase.performance
    inject('firePerf', firePerf)
    inject('firePerfObj', firePerfObj)
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE ANALYTICS -------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Analytics can only be initiated on the client side
  if (process.client) {
    await import('firebase/analytics')

    const fireAnalytics = session.analytics()
    const fireAnalyticsObj = firebase.analytics
    inject('fireAnalytics', fireAnalytics)
    inject('fireAnalyticsObj', fireAnalyticsObj)
  }
}
