import { mapGetters, mapState } from 'vuex'
import booktools from '~/mixins/booktools'

export default {
  mixins: [booktools],

  data() {
    return {
      cardCache: {},

    }
   },

  computed: {
    // ...mapState('books', ['books', 'blocks']),
    // ...mapGetters('books', [ 'getCurrentBook', 'getBlock', 'getLanguageList' ]),
    ...mapState([ 'user', 'account' ]),
    // ...mapState('users', [ 'users', 'accounts']),
    // ...mapGetters(['isUserAdmin', 'isUserEditor' ]),

    ...mapState('progress', [ 'progress' ]),

    today() {
      return Math.round((new Date()).getTime() / (1000 * 3600 * 24))
    },

    mostRecentCourse() {
      if (this.progress && this.progress.courses_recent && this.progress.courses_recent[0]) return this.progress.courses_recent[0]
    },
    mostRecentBook() {
      if (this.progress && this.progress.books_recent && this.progress.books_recent[0]) return this.progress.books_recent[0]
    },
    userAllFlashcardsList() {
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      Object.keys(this.account.flashcards).forEach(lang => {
        result = result.concat(Object.keys(this.account.flashcards[lang].scheduled))
      })
      // console.log('all user scheduled cards:', result)
      return result
    },
    userAllCardsCompleted() {
      // quick lookup list to see if a card is already completed
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      Object.keys(this.account.flashcards).forEach(lang => {
        Object.keys(this.account.flashcards[lang].completed).forEach(id=>result[id]=true)
      })
      // console.log('all user scheduled cards:', result)
      return result
    },
    flashcardsDueList() {
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      let langs = Object.keys(this.account.flashcards)
      let today = this.today
      // console.log('flashcardsDueList', langs)
      langs.forEach(lang => {
        let cardids = Object.keys(this.account.flashcards[lang].scheduled)
        // console.log('flashcardsDueList', lang, cardids)
        cardids.forEach(cardid => {
          let schedule = this.account.flashcards[lang].scheduled[cardid]
          // console.log('>> flashcardsDueList:', schedule.schedule, today)
          if (schedule.schedule <= this.today) result.push(schedule.id)
        })
      })
      // console.log('all due cards:', result.length, result)
      return result
    },

    userCards() {
      if (!this.account || ! this.account.flashcards) return []
      let result = Object.keys(this.account.flashcards).map(lang => {
        let cardset = this.account.flashcards[lang]

        let scheduled = Object.values(cardset.scheduled || {}).filter(item => !!item)
        let completed = Object.keys(cardset.completed || {}).length
        let today = this.today //Math.round((new Date()).getTime() / (1000 * 360 * 24)) // number for today
        // BUCKETS: [1, 2, 3, 5, 7, 11]
        return {
          lang,
          due: scheduled.filter(item => item.schedule<=today).length,
          bucket1: scheduled.filter(item => item.bucket===1).length,
          bucket2: scheduled.filter(item => item.bucket===2).length,
          bucket3: scheduled.filter(item => item.bucket===3).length,
          bucket4: scheduled.filter(item => item.bucket===4).length,
          bucket5: scheduled.filter(item => item.bucket===5).length,
          bucket6: scheduled.filter(item => item.bucket===6).length,
          completed,
          scheduled: scheduled.length,
          total: scheduled.length + completed
        }
      })
      // console.log(JSON.stringify(result, null, 2))
      return result
    },




  },

  methods: {
    // resumeCourse(courseid, bookid) { }
    // resumeBook(bookid)
    // setBlockDone(bookid, blockid)
    // setFlashcardCompleted(fcid)
    // scheduleFlashcard(fcid,date)
    // logProgress_reading(block)
    // logProgress_cards(fcid)
    updateUserCardProgress(prog, lang){
      console.log('Update user progress', prog, lang)
      if (prog.scheduled && lang && this.account && this.user.uid) {
        if (!prog.completed) prog.completed = {}
        this.cardPractice[lang] = prog
        this.$fireDbObj().ref(`accounts/${this.user.uid}`).child(`flashcards/${lang}`).update(prog).then(() => {
          // this.$toasted.show(`<p>Updated block: “${blid}”</p>`)
          console.log('Modified user flashcard progress', this.user.uid, lang, prog)
        })
        .catch(error => console.error(error))
      } else console.error('updateUserCardProgress did not have enough info', prog, lang, this.user.uid)
    },

    preCacheUserCards() {
      if (!this.user || !this.account) return setTimeout(this.preCacheUserCards, 1000)
      this.loadCards(this.userAllFlashcardsList)
    },

    loadCards(cards=[]) {
      return
      // console.log('loadCards', cards)
      let localReadCard = (cardid) => {
        return false
        const CACHE_TIMEOUT = 0 // 7 // one week in days
        let blobdata
        if (this.cardCache.hasOwnProperty(cardid)) return this.cardCache[cardid]
        else if (blobdata = JSON.parse(window.localStorage.getItem(`llab-${cardid}`))) {
          let {savedDate, card} = blobdata
          if (!card || !savedDate || (savedDate <= this.today-CACHE_TIMEOUT)) return false
          this.$set(this.cardCache, card.id, card)
          return card
        }
      }
      let localWriteCard = (card) => {
        // return false
        if (card) {
          window.localStorage.setItem(`llab-${card.id}`, JSON.stringify({savedDate:Date.now(), card }))
          this.$set(this.cardCache, card.id, card) // to cause reactive updates
          // console.log('local card write', card.id, card)
        }
      }
     let staleCards = cards.filter(cardid => !localReadCard(cardid))
      // let staleCards = cards.filter(()=>true)
      // console.log('Loading or refreshing', staleCards.length, 'cards', staleCards)
      // mark cards as loading so we won't try to fetch them twice
      staleCards.forEach(cardid => this.$set(this.cardCache, cardid, {id: cardid, type: 'loading' }) )
      // load cards from db and save
      // add some flag when loading is completed
      staleCards.forEach(cardid => {
        this.$fireDbObj().ref(`flashcards/${cardid}`).on("value", (snapshot) => {
          if (snapshot) {
            if (snapshot.exists()) localWriteCard(snapshot.val())
              else this.$set(this.cardCache, cardid, false)
          }
        })
      })
    },

    // getCard(cardid) {
    //   if (this.cardCache.hasOwnProperty(cardid)) return this.cardCache[cardid]
    //    else {
    //      this.loadCard(cardid)
    //      return {id: cardid, status:'loading'}
    //    }
    // },






  },





  mounted() {
    this.preCacheUserCards()
  },


}

