<template>
  <nuxt id="" class="print_layout subpixel-antialiased relative p-0" />
</template>

<script>
  import usertools from '~/mixins/usertools.js'

  export default {
    mixins: [ usertools ],
    methods: {
      checkForUpdate() {
        // this does not work.
        // if ('serviceWorker' in navigator) {
        //   navigator.serviceWorker.getRegistrations().then((registrations) => {
        //     for (let registration of registrations) registration.update()
        //   })
        // }
      },
    },
  }
</script>

<style>

#main_scrolled_container::-webkit-scrollbar {
   /* width: 0px; background: transparent; */
}

</style>