import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8ef80858 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _539d9a24 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5ea8573f = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _971eb13e = () => interopDefault(import('../pages/about/flashcards.vue' /* webpackChunkName: "pages/about/flashcards" */))
const _57b83d08 = () => interopDefault(import('../pages/about/immersive-reading.vue' /* webpackChunkName: "pages/about/immersive-reading" */))
const _54c45ee4 = () => interopDefault(import('../pages/about/mobile-app.vue' /* webpackChunkName: "pages/about/mobile-app" */))
const _201e0dec = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _6281ba09 = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _976edaea = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _21dac758 = () => interopDefault(import('../pages/account/logout.vue' /* webpackChunkName: "pages/account/logout" */))
const _80ca1b6a = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _02022ac7 = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _6f66deee = () => interopDefault(import('../pages/admin/books.vue' /* webpackChunkName: "pages/admin/books" */))
const _7462adfc = () => interopDefault(import('../pages/admin/courses.vue' /* webpackChunkName: "pages/admin/courses" */))
const _957fe942 = () => interopDefault(import('../pages/admin/flashcards.vue' /* webpackChunkName: "pages/admin/flashcards" */))
const _766e6519 = () => interopDefault(import('../pages/admin/payments.vue' /* webpackChunkName: "pages/admin/payments" */))
const _27a896ea = () => interopDefault(import('../pages/admin/user-access.vue' /* webpackChunkName: "pages/admin/user-access" */))
const _708d487a = () => interopDefault(import('../pages/courses/bahai_arabic_immersion-en_ar.vue' /* webpackChunkName: "pages/courses/bahai[_]arabic_immersion-en_ar" */))
const _4bf28161 = () => interopDefault(import('../pages/courses/bahai_farsi_introduction-en_fa.vue' /* webpackChunkName: "pages/courses/bahai[_]farsi_introduction-en_fa" */))
const _56849486 = () => interopDefault(import('../pages/courses/farsi_deep_dive-en_fa.vue' /* webpackChunkName: "pages/courses/farsi[_]deep_dive-en_fa" */))
const _40e86c0a = () => interopDefault(import('../pages/courses/farsi_immersion-en_fa.vue' /* webpackChunkName: "pages/courses/farsi[_]immersion-en_fa" */))
const _cf4425f4 = () => interopDefault(import('../pages/courses/magic_spanish-en_es.vue' /* webpackChunkName: "pages/courses/magic[_]spanish-en_es" */))
const _cd8da2f6 = () => interopDefault(import('../pages/courses/military_arabic-en_ar.vue' /* webpackChunkName: "pages/courses/military[_]arabic-en_ar" */))
const _15e72464 = () => interopDefault(import('../pages/courses/military_immersion_farsi-en_fa.vue' /* webpackChunkName: "pages/courses/military[_]immersion_farsi-en_fa" */))
const _48c96269 = () => interopDefault(import('../pages/courses/romance_arabic-en_ar.vue' /* webpackChunkName: "pages/courses/romance[_]arabic-en_ar" */))
const _34fabe50 = () => interopDefault(import('../pages/user/account/index.vue' /* webpackChunkName: "pages/user/account/index" */))
const _761ba3a6 = () => interopDefault(import('../pages/user/course/index.vue' /* webpackChunkName: "pages/user/course/index" */))
const _57ac068a = () => interopDefault(import('../pages/user/flashcards.vue' /* webpackChunkName: "pages/user/flashcards" */))
const _56f9e1e6 = () => interopDefault(import('../pages/user/library/index.vue' /* webpackChunkName: "pages/user/library/index" */))
const _ee544aa2 = () => interopDefault(import('../pages/user/account/profile.vue' /* webpackChunkName: "pages/user/account/profile" */))
const _4cb16811 = () => interopDefault(import('../pages/admin/editcourse/_key/index.vue' /* webpackChunkName: "pages/admin/editcourse/[_]key/index" */))
const _348a71ae = () => interopDefault(import('../pages/user/course/_courseid.vue' /* webpackChunkName: "pages/user/course/[_]courseid" */))
const _5d448877 = () => interopDefault(import('../pages/user/library/_bookid.vue' /* webpackChunkName: "pages/user/library/[_]bookid" */))
const _0f7aeee9 = () => interopDefault(import('../pages/admin/editbook/_key/edit.vue' /* webpackChunkName: "pages/admin/editbook/[_]key/edit" */))
const _32be6744 = () => interopDefault(import('../pages/admin/editbook/_key/print.vue' /* webpackChunkName: "pages/admin/editbook/[_]key/print" */))
const _fcdeacda = () => interopDefault(import('../pages/study/_courseid.vue' /* webpackChunkName: "pages/study/[_]courseid" */))
const _3723c235 = () => interopDefault(import('../pages/courses/_courseid/signup.vue' /* webpackChunkName: "pages/courses/[_]courseid/signup" */))
const _2b41e0d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _8ef80858,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/contact",
    component: _539d9a24,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/courses",
    component: _5ea8573f,
    meta: {},
    alias: ["/amp/courses"],
    name: "courses"
  }, {
    path: "/about/flashcards",
    component: _971eb13e,
    meta: {},
    alias: ["/amp/about/flashcards"],
    name: "about-flashcards"
  }, {
    path: "/about/immersive-reading",
    component: _57b83d08,
    meta: {},
    alias: ["/amp/about/immersive-reading"],
    name: "about-immersive-reading"
  }, {
    path: "/about/mobile-app",
    component: _54c45ee4,
    meta: {},
    alias: ["/amp/about/mobile-app"],
    name: "about-mobile-app"
  }, {
    path: "/about/privacy",
    component: _201e0dec,
    meta: {},
    alias: ["/amp/about/privacy"],
    name: "about-privacy"
  }, {
    path: "/about/terms",
    component: _6281ba09,
    meta: {},
    alias: ["/amp/about/terms"],
    name: "about-terms"
  }, {
    path: "/account/login",
    component: _976edaea,
    meta: {},
    alias: ["/amp/account/login"],
    name: "account-login"
  }, {
    path: "/account/logout",
    component: _21dac758,
    meta: {},
    alias: ["/amp/account/logout"],
    name: "account-logout"
  }, {
    path: "/account/profile",
    component: _80ca1b6a,
    meta: {},
    alias: ["/amp/account/profile"],
    name: "account-profile"
  }, {
    path: "/account/verify",
    component: _02022ac7,
    meta: {},
    alias: ["/amp/account/verify"],
    name: "account-verify"
  }, {
    path: "/admin/books",
    component: _6f66deee,
    meta: {},
    alias: ["/amp/admin/books"],
    name: "admin-books"
  }, {
    path: "/admin/courses",
    component: _7462adfc,
    meta: {},
    alias: ["/amp/admin/courses"],
    name: "admin-courses"
  }, {
    path: "/admin/flashcards",
    component: _957fe942,
    meta: {},
    alias: ["/amp/admin/flashcards"],
    name: "admin-flashcards"
  }, {
    path: "/admin/payments",
    component: _766e6519,
    meta: {},
    alias: ["/amp/admin/payments"],
    name: "admin-payments"
  }, {
    path: "/admin/user-access",
    component: _27a896ea,
    meta: {},
    alias: ["/amp/admin/user-access"],
    name: "admin-user-access"
  }, {
    path: "/courses/bahai_arabic_immersion-en_ar",
    component: _708d487a,
    meta: {},
    alias: ["/amp/courses/bahai_arabic_immersion-en_ar"],
    name: "courses-bahai_arabic_immersion-en_ar"
  }, {
    path: "/courses/bahai_farsi_introduction-en_fa",
    component: _4bf28161,
    meta: {},
    alias: ["/amp/courses/bahai_farsi_introduction-en_fa"],
    name: "courses-bahai_farsi_introduction-en_fa"
  }, {
    path: "/courses/farsi_deep_dive-en_fa",
    component: _56849486,
    meta: {},
    alias: ["/amp/courses/farsi_deep_dive-en_fa"],
    name: "courses-farsi_deep_dive-en_fa"
  }, {
    path: "/courses/farsi_immersion-en_fa",
    component: _40e86c0a,
    meta: {},
    alias: ["/amp/courses/farsi_immersion-en_fa"],
    name: "courses-farsi_immersion-en_fa"
  }, {
    path: "/courses/magic_spanish-en_es",
    component: _cf4425f4,
    meta: {},
    alias: ["/amp/courses/magic_spanish-en_es"],
    name: "courses-magic_spanish-en_es"
  }, {
    path: "/courses/military_arabic-en_ar",
    component: _cd8da2f6,
    meta: {},
    alias: ["/amp/courses/military_arabic-en_ar"],
    name: "courses-military_arabic-en_ar"
  }, {
    path: "/courses/military_immersion_farsi-en_fa",
    component: _15e72464,
    meta: {},
    alias: ["/amp/courses/military_immersion_farsi-en_fa"],
    name: "courses-military_immersion_farsi-en_fa"
  }, {
    path: "/courses/romance_arabic-en_ar",
    component: _48c96269,
    meta: {},
    alias: ["/amp/courses/romance_arabic-en_ar"],
    name: "courses-romance_arabic-en_ar"
  }, {
    path: "/user/account",
    component: _34fabe50,
    meta: {},
    alias: ["/amp/user/account"],
    name: "user-account"
  }, {
    path: "/user/course",
    component: _761ba3a6,
    meta: {},
    alias: ["/amp/user/course"],
    name: "user-course"
  }, {
    path: "/user/flashcards",
    component: _57ac068a,
    meta: {},
    alias: ["/amp/user/flashcards"],
    name: "user-flashcards"
  }, {
    path: "/user/library",
    component: _56f9e1e6,
    meta: {},
    alias: ["/amp/user/library"],
    name: "user-library"
  }, {
    path: "/user/account/profile",
    component: _ee544aa2,
    meta: {},
    alias: ["/amp/user/account/profile"],
    name: "user-account-profile"
  }, {
    path: "/admin/editcourse/:key?",
    component: _4cb16811,
    meta: {},
    alias: ["/amp/admin/editcourse/:key?"],
    name: "admin-editcourse-key"
  }, {
    path: "/user/course/:courseid",
    component: _348a71ae,
    meta: {},
    alias: ["/amp/user/course/:courseid"],
    name: "user-course-courseid"
  }, {
    path: "/user/library/:bookid",
    component: _5d448877,
    meta: {},
    alias: ["/amp/user/library/:bookid"],
    name: "user-library-bookid"
  }, {
    path: "/admin/editbook/:key?/edit",
    component: _0f7aeee9,
    meta: {},
    alias: ["/amp/admin/editbook/:key?/edit"],
    name: "admin-editbook-key-edit"
  }, {
    path: "/admin/editbook/:key?/print",
    component: _32be6744,
    meta: {},
    alias: ["/amp/admin/editbook/:key?/print"],
    name: "admin-editbook-key-print"
  }, {
    path: "/study/:courseid?",
    component: _fcdeacda,
    meta: {},
    alias: ["/amp/study/:courseid?"],
    name: "study-courseid"
  }, {
    path: "/courses/:courseid/signup",
    component: _3723c235,
    meta: {},
    alias: ["/amp/courses/:courseid/signup"],
    name: "courses-courseid-signup"
  }, {
    path: "/",
    component: _2b41e0d6,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
