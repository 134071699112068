<template>
<div class="flex flex-col subpixel-antialiased relative h-screen overflow-hidden">
  <Header />
  <nuxt class="flex flex-col p-0 xs:px-1 sm:px-2 relative overflow-hidden"
        :class="[compactHeight?'-mt-2':'']"
    :style="compactHeight?'min-width:320px;max-height: calc(100vh - 28px);':'min-width:320px;max-height: calc(100vh - 70px);'" />
  <Footer v-if="!compactHeight" />
</div>
</template>

<script>
  import Header from '~/components/Header'
  import Footer from '~/components/Footer'
  import usertools from '~/mixins/usertools.js'

  export default {
    components: { Header, Footer },
    data () {
      return {
        clientHeight: document.documentElement.clientHeight,
      }
    },
    computed: {
      compactHeight() { return this.clientHeight < 500 }
    },
    mixins: [ usertools ],

    methods: {
      handleResize() { this.clientHeight = document.documentElement.clientHeight },
    },

    // enforce user authentication
    watch: {
      '$route'() { this.checkUserVerified() },
      user() { this.checkUserVerified() },
    },

    mounted: function() { window.addEventListener('resize', this.handleResize) },
    beforeDestroy: function() { window.removeEventListener('resize', this.handleResize) },

  }
</script>

