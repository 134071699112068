import firebase from 'firebase'
import { vuexfireMutations, firebaseAction } from 'vuexfire'

// temporary fix
export const strict = false

//****************************************/
export const state = () => ({
  user: null,
  account: null,
  // appVersion: process.env.PACKAGE_VERSION || '0',
  // userEmailVerified: false,
})

//****************************************/
export const getters = {
  userEmail (state) {
    if (state.user && state.user.hasOwnProperty('email')) return state.user.email
  },

  // isUserAdmin (state) {
  //   return !!state.account && state.account.hasOwnProperty("roles")
  //     && state.account.roles.hasOwnProperty("admin")
  //     && !!state.account.roles.admin
  // },

  // isUserEditor (state) {
  //   return !!state.account && state.account.hasOwnProperty("roles")
  //     && state.account.roles.hasOwnProperty("editor")
  //     && !!state.account.roles.editor
  // },
}

//****************************************/
export const actions = {

  setAccountRef: firebaseAction(({ bindFirebaseRef, state }, uid) => {
    if (state.account && state.account.uid && state.account.uid===uid){
      return console.log('setAccountRef', 'account already loaded', uid) // already set up
    }
    else {
      console.log('setAccountRef, loading user account', uid)
      state.account = null
      return bindFirebaseRef('account', firebase.database().ref(uid))
    }
  }),

  resetUser ({ state }) {
    state.user = null
    state.account = null
    // state.userEmailVerified = false
  },

  userCreate ({ state }, newUser) {
    if (!newUser) console.error('Warning: userCreate called without valid user')
    else {
      console.log('userCreate:', newUser)
      return firebase.auth()
        .createUserWithEmailAndPassword(newUser.email, newUser.password)
        .then((user) => createNewAccount(user) )
    }
  },

  userLogin ({ state, dispatch }, loginUser) {
    if (!loginUser.email)  console.error('Warning login without valid user:', loginUser)
    else {
      console.log('userLogin:', loginUser)
      return firebase.auth()
        .signInWithEmailAndPassword(loginUser.email, loginUser.password)
        .then((user) => {
          console.log('logged in with user', user)
          return dispatch('setUser', user)
        })
    }
  },

  userLogout ({ state, dispatch }) {
    return firebase.auth()
      .signOut()
      .then(() => {
        dispatch('resetUser')
      })
  },

  accountUpdate ({ state }, update) {
    return firebase.database().ref('accounts').child(state.user.uid).update(update).then(() => {
      //
    }).catch(error => console.error(error))
  },


  userUpdate ({ state }, update) {
    return firebase.auth().currentUser.updateProfile(update).then(function() {
      return firebase.database().ref('accounts').child(state.user.uid).update(update).then(() => {
        //
      })
      .catch(error => console.error(error))
    }).catch(function(error) { console.error(error)  }) // An error happened.
  },

  setUser ({state, dispatch, commit}, { user } ) {
    if (!user) return console.error('Warning, setUser called without user!!', user)
    else {
      if (state.user && state.account &&
          (user.uid === state.user.uid) &&
           (user.uid === state.account.uid)) {
             return console.log('setUser', 'user already logged in', user.uid)
      } else {
        console.log('setUser', 'loading user', user.uid)
        state.user = user
        return dispatch('setAccountRef', `accounts/${user.uid}`)
      }

    }
  },

  sendVerificationEmail ({state}) {
    if (!firebase.auth().currentUser) return
    const minimumEmailResendIntervalSeconds = 100
    let lastEmailtime = Date.parse(localStorage.getItem("lastVerificationEmail", 0))
    let elapsedSeconds = Math.round((new Date()-lastEmailtime) / 1000)
    if (!elapsedSeconds || elapsedSeconds > minimumEmailResendIntervalSeconds) {
      firebase.auth().currentUser.sendEmailVerification()
      // console.log('Requested new verification email.')
      localStorage.setItem("lastVerificationEmail", new Date().toString())
    }
    // else console.log(`${elapsedSeconds} seconds since last verification email.`)
  },

  reloadUser ({state, dispatch}) {
    let user = firebase.auth().currentUser
    if (user) user.reload().then( () => {
      // console.log('Reloaded user', user.uid, 'emailVerified: '+user.emailVerified)
      dispatch('setUser', {user} )
    })
  }
}

//****************************************/
export const mutations = {
  ...vuexfireMutations,

  //setUser_once (state, user) {
    // console.log('mutation setUser', state, user)
   // state.user = user
    // monitor user auth changes
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) { // User is signed in.
    //      console.log('auth Change: logged in', user)
    //     state.user = user
    //     state.userEmailVerified = user.emailVerified
    //     // need to update account now... like  dispatch('setAccountRef', `accounts/${user.uid}`)
    //   } else {  // User not signed in.
    //     state.user = null
    //     state.account = null
    //     state.userEmailVerified = null
    //   }
    // })
  //}
}


// helpers
function createNewAccount ({ user }) {
  // attach affiliate id to user account
  if (localStorage.getItem("affid", '')) {
    let affid = localStorage.getItem("affid", '')
    // console.log(`  affiliate id: "${affid}"`)
    firebase.database().ref(`accounts/${user.uid}/marketing`).update({affiliate: affid})
  }
  // return reference to user account
  return firebase.database().ref(`accounts/${user.uid}`)
}