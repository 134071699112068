import { firebaseAction } from 'vuexfire'

//****************************************/
export const state = () => ({
  currentCourse: {id: ''},
  allCourses: [],
})


//****************************************/
export const getters = {
  // getCurrentBook(state) {
  //   let book = state.books.filter(bk => bk.id===state.currentBook.id)
  //   if (book) return book[0]
  // },
  // getRandomBook(state) {
  //   return state.books[Math.floor(Math.random()*state.books.length)]
  // },
  // getBook: (state) => (bookid) => {
  //   let index = state.books.map(bk=> bk.id).indexOf(bookid)
  //   if (index>-1) return state.books[index]
  // },
  // getBlock: (state) => (blockid) => {
  //   let index = state.blocks.map(bl=> bl.id).indexOf(blockid)
  //   if (index>-1) return state.blocks[index]
  //    else return {isLoading: true, content: []}
  // },
  // getLanguageList(state) { // list of all lang2 language codes
  //   return state.books.map(bk => bk.lang2).filter((v,i,s) => s.indexOf(v)===i)
  // },
  // getSelectionObj(state) {
  //   return tokenObj(state.selectionToken)
  // },

}

//****************************************/
export const mutations = {
  setCurrentCourse(state, courseid) {
    state.currentcourse.id = courseid
  },
  // setSelectionToken(state, token) {
  //   state.selectionToken = token
  // },
  // setUserReadingLevel(state, level) {
  //   state.userReadingLevel = level
  // }
}

export const actions = {
  // loadCourse: firebaseAction(({state, getters, commit, bindFirebaseRef}, bookid) => {
  //   if (!bookid || !getters.getBook(bookid)) return
  //   // console.log('loadBook', bookid)
  //   // commit('setCurrentBook', bookid)
  //   return bindFirebaseRef('blocks', this.$fireDbObj().ref(`blocks/${bookid}/`)) //.orderByChild("book").equalTo(bookid) )
  // }),

  bindCoursesRef: firebaseAction(async function ({ bindFirebaseRef }) {
    console.log('bindCoursesRef')
    return bindFirebaseRef('allCourses', this.$fireDbObj().ref('courses'))
  }),
}



// function tokenObj(token) {
//   let [blid, type, snt, phr, wrd] = token.split(':').map((v,i)=> i<=1 ? v : parseInt(v))
//   if (!type) return {blid}
//     else if (type==='snt') return {blid,type,snt}
//     else if (type==='phr') return {blid,type,snt,phr}
//     else if (type==='wrd') return {blid,type,snt,phr,wrd}
// }


    // setCurrentBookid(store, bookid) {
    //   // console.log(`setCurrentBookid(${bookid})`, store)
    //   store.bookid = bookid
    // },
    // setCurrentBookLoaded(store, isLoaded) {
    //   // console.log(`setCurrentBookLoaded(${isLoaded})`, store)
    //   store.book_loaded = isLoaded
    // },
    // clearCurrentBookData(store) {
    //   store.book = null
    // },
    // setBlock(store, bid, block) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['l1']) store.book.l1 = {}
    //   Vue.set(store.book.l1, bid, block)
    // },
    // setBlock_l2(store, bid, l2) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['l2']) store.book.l2 = {}
    //   Vue.set(store.book.l2, bid, l2)
    // },
    // setBlock_audiomap(store, bid, mapdata) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['audiomap']) store.book.audiomap = {}
    //   Vue.set(store.book.audiomap, bid, mapdata)
    // },
    // setBlock_words(store, bid, words) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['words']) store.book.words = {}
    //   Vue.set(store.book.words, bid, words)
    // },
    // setBlock_phrases(store, bid, phrases) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['phrases']) store.book.phrases = {}
    //   Vue.set(store.book.phrases, bid, phrases)
    // },
    // setBlock_assignments(store, bid, assignments) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['assignments']) store.book.assignments = {}
    //   Vue.set(store.book.assignments, bid, assignments)
    // },
    // setBlock_meta(store, field, value) {
    //   if (!store.book) store.book = {}
    //   Vue.set(store.book, field, value)
    // },




  // actions: {
  //  loadBook({state, getters, commit, dispatch}, bookid) {
  //   //  console.log(`store: loadBook(${bookid})`)
  //    // if bookid = current_book, exit
  //    if (bookid === state.bookid) return
  //    console.log(`store: loading book: (${bookid})`)
  //    // set book_loaded false  // set book null  // set current_book = bookid
  //    commit('setCurrentBookid', bookid)
  //    commit('setCurrentBookLoaded', false)
  //    commit('clearCurrentBookData')
  //    // load mock data
  //    loadMockData(state, commit).then(() => {
  //      commit('setCurrentBookLoaded', true)
  //      console.log('!!data loaded!!', state.book_loaded)
  //    })
  //  },
  // }







